import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import BackgroundImg from "../components/background.js"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges


  var startDate = new Date("2020-07-09")
  var today = new Date()
  today.setHours(0, 0, 0)
  var timeDifference = today.getTime() - startDate.getTime()
  var numberOfDays = Math.round(timeDifference / (1000 * 60 * 60 * 24))

  var debug = false
  if (debug) {
    today = new Date()
    timeDifference = today.getTime() - startDate.getTime()
    numberOfDays = Math.round(timeDifference / (1000 * 60))
    console.log("number of days " + numberOfDays)
  }

  while (numberOfDays >= 164) {
    numberOfDays = numberOfDays - 164
  }

  numberOfDays = Math.max(0, Math.min(numberOfDays, 164))

  const pageContent = (
    <div
      style={{
        backgroundColor: "dimgray",
      }}
    >
      <BackgroundImg>
        <Layout location={location} title={siteTitle}>
          <SEO title="Dagens vers" />
          {/* <Bio /> */}
          {/* {[posts[numberOfDays]].map( */}
          {posts.map((
            { node }) => {
            if (parseInt(node.frontmatter.number) == numberOfDays + 1) {
              const title = node.frontmatter.title
              const content = node.frontmatter.description 
              const post = node.rawMarkdownBody

              return (
                <article key={node.fields.slug}>
                  <header>
                    <h1
                      style={{
                        marginBottom: rhythm(1 / 4),
                        marginTop: rhythm(1.0),
                        color: "lightgray",
                      }}
                    >
                      {title}
                    </h1>
                  </header>
                  <section>
                    <p
                      key={node.frontmatter.title}
                      style={{
                        height: "60vh",
                        color: "lightgray",
                        textShadowColor: "black",
                        textShadowOffset: { width: -1, height: 0 },
                        textShadowRadius: 10,
                        fontSize: "1.5em",
                        whiteSpace: 'pre-wrap',
                      }}
                      // dangerouslySetInnerHTML={{
                      //     __html: content,
                      //   }}
                    >
                      {/* {`
                        test1
                        test2

                        test3
                      `} */}
                     {/* {content} */}
                     {post}
                    </p>
                  </section>
                </article>
              )
            }
          })}
        </Layout>
      </BackgroundImg>
    </div>
  )

  return pageContent
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: ASC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          html
          rawMarkdownBody
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            number
            description
          }
        }
      }
    }
  }
`
